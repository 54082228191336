import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getEaproLtdData, } from '../../Redux/Action/Action';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EaproGlobalLtdBanner from './EaproGlobalLtdBanner/EaproGlobalLtdBanner'
import EaproGlobalLtdContent from './EaproGlobalLtdContent/EaproGlobalLtdContent'
import EaproGlobalLtdForms from './EaproGlobalLtdContent/EaproGlobalLtdForms'
import { Helmet } from "react-helmet";
const EaproGlobalLtd = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEaproLtdData());

    }, []);

    const eaproLtdData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.eaproLtdDataList?.data) : []);

    return (
        <>
            <Helmet>
                {/* Page Title and Meta Tags */}
                <title>Global Energy Ltd</title>
                <meta
                    name="description"
                    content="Discover Global Energy Ltd by EAPRO, a leader in renewable energy solutions. Learn about our innovative solar products and services, committed to powering a sustainable future globally."
                />
                <meta name="keywords" content="EAPRO Overseas" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/global-energy-ltd/" />

               
            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EaproGlobalLtdBanner eaproLtdData={eaproLtdData} />
            <EaproGlobalLtdContent eaproLtdData={eaproLtdData} />
            <EaproGlobalLtdForms />
            <Footer />
        </>
    )
}

export default EaproGlobalLtd