import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import './Banner.css';
import './ResponsiveBanner.css';
import parse from 'html-react-parser'
import { getHomeBannerData } from '../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const Banner = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomeBannerData());

    }, []);

    const homeBannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.homeBannerDataList?.data) : []);

    return (
        <>
            {homeBannerData?.length !== 0 ? (
                <section className="banner-section">
                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {homeBannerData?.map((_, index) => (
                                <input
                                    key={index}
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? 'active' : ''}
                                    aria-current={index === 0 ? 'true' : undefined}
                                    aria-label={`Slide ${index + 1}`}
                                />
                            ))}
                        </div>

                        <div className="carousel-inner">
                            {homeBannerData?.map((data, index) => {
                                // Check if the file is a video or an image using regex
                                const isVideo = /\.(mp4|mov|avi|webm|mkv)$/i.test(data?.image);
                                const mediaSrc = data?.image ? `${host}/${data?.image}` : "";

                                return (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                        {/* Render video or image based on the file type */}
                                        {isVideo ? (
                                            <>
                                                {/* Video rendering for desktop */}
                                                <video
                                                    className="d-none d-md-block d-lg-block d-xl-block img-fluid w-100"
                                                    src={mediaSrc}
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                    loading="eager"
                                                >
                                                    Your browser does not support the video tag.
                                                </video>

                                                {/* Video rendering for mobile */}
                                                <video
                                                    className="d-block d-md-none d-lg-none d-xl-none img-fluid w-100"
                                                    src={mediaSrc}
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                    loading="eager"
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                            </>

                                        ) : (
                                            <>
                                                {/* Image rendering */}
                                                <img
                                                    src={mediaSrc}
                                                    className="d-none d-md-block d-lg-block d-xl-block img-fluid w-100"
                                                    alt="EAPRO Banner"
                                                    loading="eager"
                                                    onError={(e) => (e.target.src = '/img/avatar.png')}
                                                />
                                                <img
                                                    src={mediaSrc}
                                                    className="d-block d-md-none d-lg-none d-xl-none img-fluid w-100"
                                                    alt="EAPRO Banner"
                                                    loading="eager"
                                                    onError={(e) => (e.target.src = '/img/avatar.png')}
                                                />
                                            </>
                                        )}

                                        <div className="carousel-caption">
                                            <div className="container">
                                                <div className="carousel-width">
                                                    <h1 className="banner-heading">{data?.title || ''}</h1>
                                                    <p className="para-name">{data?.summary ? parse(data?.summary) : ''}</p>
                                                    {data?.link && (
                                                        <div className="learn-more-btn">
                                                            <a href={`${data.link}`}>
                                                                <i className="fa fa-angle-double-right" aria-hidden="true"></i> Learn More
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Carousel controls (Optional, uncomment if needed) */}

                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>

                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>

                    </div>
                </section>
            ) : (
                <section className="banner-section"></section>
            )}
        </>

    )
}

export default Banner