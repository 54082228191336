import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import "./OurProductSection.css"
import "./ResponsiveOurProductSection.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import { getCategoryData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;

const OurProductSection = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoryData());

    }, []);
    const navigate = useNavigate();
    const categoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.categoryDataList?.data) : []);

    const handlePowerClick = (name) => {
        if (name !== undefined) {
            navigate(`/products/${name}`);
        } else {
            toast.error("There is no such product");
        }


    }


    const Slider_group = {
        dots: false,
        nav: false,
        infinite: categoryData?.length > 2,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
            <section className='our-product-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h3>Our Products</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
                            <Slider {...Slider_group} className=" product-card-row">
                                {/* <div className="col-md-1"></div> */}
                                {categoryData?.map((data) => (
                                    <div className=" product-card-items" onClick={() => { handlePowerClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                        <a >
                                            <div className="product-card-right" style={{ cursor: "pointer" }}>
                                                <div className="product-heading">
                                                    <h6>{data?.name}</h6>
                                                </div>
                                                <div className="product-image">
                                                    <img src={data?.image ? `${host}/${data?.image}` : ""} alt={data?.name} className='img-fluid' />
                                                </div>
                                                <div className="product-details-content">
                                                    <p>{data?.subtitle}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>))}

                                {/* <div className="col-md-1"></div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurProductSection