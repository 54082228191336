import React from 'react'
import "./EventPageDetailsBanner.css"
import "./ResponsiveEventPageDetailsBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const EventPageDetailsBanner = (newsData) => {
    return (
        <>
            {/* <section className='event-details-banner-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className='business-parent-dv'>
                                <div className="business-heading text-start">
                                    <img src="/images/help-and-support/heading-bg.png" alt="" />
                                    <h1>News & Event Details</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='event-details-banner-section' >
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src={newsData?.newsData?.banner ? `${host}/${newsData?.newsData?.banner}` : "/images/blog/banner-bg.png"} alt="" className='img-fluid banner-img-tag' />
                            {/* <div className="banner-top-content-absol business-heading text-start">
                                <img src="/images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                <div className='business-banner-heading'>
                                    <div className="container">
                                        <h1>News & Event</h1>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EventPageDetailsBanner