import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "./PillarsOfEAPRO.css"
import "./ResponsivePillarsOfEAPRO.css"
import Slider from "react-slick";
import parse from 'html-react-parser';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import { getPillarsData } from '../../../Redux/Action/Action';
import { Link } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const PillarsOfEAPRO = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getPillarsData());

    }, []);

    const pillarsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.pillarsDataList?.data) : []);


    const handleClick = (sname) => {

        if (sname != undefined) {
            navigate(`/pillar-video-details/${sname}`)
        }
    }


    const Slider_group = {
        dots: false,
        nav: false,
        infinite: pillarsData?.length > 5,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: pillarsData?.length > 3,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: pillarsData?.length > 2,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: pillarsData?.length > 1,
                }
            }
        ]
    };

    return (
        <>
            <section className='pillars-of-eapro-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="specialization-heading-dv">
                                <h3>5 Pillars of EAPRO</h3>
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-12">
                            {pillarsData?.length != "0" ?
                                <Slider {...Slider_group} className=" piller-card-row">
                                    {/* <div className="col-md-1 col-lg-1 col-12"></div> */}

                                    {pillarsData?.map((data, index) => (
                                        <div className="piller-items">
                                            <div className="piller-card-parent">
                                                <div className="piller-img">
                                                    <img src={data?.image ? `${host}/${data?.image}` : ""} style={{ borderRadius: '50%' }}
                                                        width={200} height={200} alt={data?.title} className='img-fluid' />
                                                </div>
                                                <div className="piller-content">
                                                    <h4>{data?.title}</h4>
                                                    <p>{data ? parse(data?.summary) : ""}</p>

                                                    <div onClick={() => { handleClick(data?.slug) }} style={{ cursor: 'pointer' }} className='piller-read-more'>Read More</div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                                :
                                ""
                            }


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PillarsOfEAPRO