import React from 'react'
import Navbar from '../../../Header/Navbar'
import NavbarSecond from '../../../Header/NavbarSecond'
import MobileNavbar from '../../../Header/MobileNavbar'
import Footer from '../../../Footer/Footer'
import LoadCalculator from './LoadCalculator'
import { Helmet } from 'react-helmet'

const LoadCalculatorHome = () => {
    return (
        <>
            <Helmet>
                <title>Load Calculator</title>
                <meta name="description" content="Use our Load Calculator to estimate your energy requirements. Accurately determine the power needs for your home or business and make informed decisions for efficient solar system design." />
                <meta name="keywords" content="Load Calculator" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/load-calculator/" />

            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <LoadCalculator />
            <Footer />
        </>
    )
}

export default LoadCalculatorHome