import React from 'react'
import parse from 'html-react-parser';
import "./BlogDetailsContent.css"

const BlogDetailsContent = ({blogData}) => { 

    const url = blogData?.link
    const videoId = url?.split('/')[3]?.split('?')[0];

    // Construct the embed URL format
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <>
        
            <section className='blog-details-con-page-content-section'>

                {blogData ?
                    <div className="container">
                        <h1>{blogData?.title}</h1>
                        <div className="blog-details-inner">
                            {blogData?.summary ? <b> {parse(blogData?.summary)}</b> :
                                ""}
                        </div>

                        {blogData?.link && <div className="blog-details-inner-video">
                            <iframe width="100%" height="391" src={blogData?.link ? `${embedUrl}` : "https://www.youtube.com/embed/FFBnPT1Z5uA"} title={blogData?.title ? blogData?.title : "EAPRO Industrial Visit by Teerthanker Mahaveer University"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>}
                        {blogData?.description ? parse(blogData?.description) :
                            ""}

                    </div>
                    :
                    ""

                }
            </section >
        </>
    )
}

export default BlogDetailsContent