import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ServiceCenterLocatorPageBanner from './ServiceCenterLocatorPageBanner/ServiceCenterLocatorPageBanner'
import ServiceCenterLocatorPageContent from './ServiceCenterLocatorPageContent/ServiceCenterLocatorPageContent'
import { getAllBannerData } from '../../Redux/Action/Action';
import { Helmet } from 'react-helmet';

const ServiceCenterLocatorPagePage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);

    return (
        <>
            <Helmet>
                <title>Service Center Locator | EAPRO</title>
                <meta name="description" content="Find the nearest EAPRO service center with our easy-to-use locator. Get expert support and maintenance for your solar products, ensuring optimal performance and longevity." />
                <meta name="keywords" content="Service Center Locator" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/service-center-locator/" />
            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ServiceCenterLocatorPageBanner bannerData={bannerData} />
            {/* <ServiceCenterLocatorPageContent /> */}
            <Footer />
        </>
    )
}

export default ServiceCenterLocatorPagePage