import React from 'react'
import "./EfficientMechanicalSolarBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const EfficientMechanicalSolarBanner = (efficientMechanicalData) => {

    return (
        <>
            <section className='efficient-mechnical-solar-banner-section' style={{ backgroundImage: `url(${host}/${efficientMechanicalData?.efficientMechanicalData?.banner_image ? efficientMechanicalData?.efficientMechanicalData?.banner_image : "../../../../public/images/global-energy/blog-detail-banner.jpg"})` }}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            {/* <div className="business-heading text-start">
                                <img src="/images/help-and-support/heading-bg.png" alt="" />
                                <h1>Help & Support</h1>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EfficientMechanicalSolarBanner