import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EAPROOverseasBanner from './EAPROOverseasBanner/EAPROOverseasBanner'
import EAPROOverseasContent from './EAPROOverseasContent/EAPROOverseasContent'
import EAPROOverseasForms from './EAPROOverseasContent/EAPROOverseasForms'
import { getEaproOverseasData } from '../../Redux/Action/Action';
import { Helmet } from "react-helmet";
const EAPROOverseas = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEaproOverseasData());

    }, []);

    const eaproOverseasData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.eaproOverseasDataList?.data) : []);

    return (
        <>
            <Helmet>
                {/* Page Title and Meta Tags */}
                <title>Eapro Overseas</title>
                <meta
                    name="description"
                    content="Explore EAPRO Overseas for global solutions in solar energy. Discover our international presence and commitment to providing high-quality solar products and services across the world."
                />
                <meta name="keywords" content="Eapro Overseas" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/eapro-overseas/" />

                {/* Organization Schema */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "EAPRO Global Ltd.",
                        "url": "https://www.eapro.in/",
                        "logo": "https://www.eapro.in/images/logo.png",
                        "sameAs": [
                            "https://www.facebook.com/EaproGlobalLtd/",
                            "https://www.instagram.com/eaprogloballtd/",
                            "https://www.youtube.com/@eaprogloballimited",
                        ],
                    })}
                </script>

                {/* Website Schema */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "EAPRO Global Ltd.",
                        "url": "https://www.eapro.in/",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.eapro.in/{search_term_string}",
                            "query-input": "required name=search_term_string",
                        },
                    })}
                </script>
            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EAPROOverseasBanner eaproOverseasData={eaproOverseasData} />
            <EAPROOverseasContent eaproOverseasData={eaproOverseasData} />
            <EAPROOverseasForms />
            <Footer />
        </>
    )
}

export default EAPROOverseas