import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ContactUsPageNewBanner from './ContactUsPageNewBanner/ContactUsPageNewBanner'
import ContactUsPageNewContent from './ContactUsPageNewContent/ContactUsPageNewContent'
import { getContactDetailsData } from '../../Redux/Action/Action';
import { Helmet } from 'react-helmet';

const ContactUsPageNew = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactDetailsData());
    }, []);

    const contactDetailsData = useSelector((state) => state?.Reducer?.contactDetailsDataList?.data || []);

    return (
        <>
            <Helmet>
                <title>Contact Us | EAPRO</title>
                <meta name="description" content="Get in touch with EAPRO for inquiries, support, or more information about our solar solutions. Our team is ready to assist you with your energy needs and provide expert guidance." />
                <meta name="keywords" content="Contact Us" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/contact-us/" />
            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ContactUsPageNewBanner contactDetailsData={contactDetailsData} />
            <ContactUsPageNewContent contactDetailsData={contactDetailsData} />
            <Footer />
        </>
    )
}

export default ContactUsPageNew