
import { React, useEffect } from 'react'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import CsrContent from './CsrContent/CsrContent'
import CsrBanner from './CsrBanner/CsrBanner'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { getAllBannerData } from '../../Redux/Action/Action'
import { Helmet } from 'react-helmet'
const Csr = () => {
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);

    return (
        <>
            <Helmet>

                <title>CSR | Eapro</title>
                <meta name="description" content="Discover EAPRO commitment to corporate social responsibility (CSR). Learn about our initiatives focused on sustainability, community development, and making a positive impact on society." />
                <meta name="keywords" content="CSR" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/csr/" />


            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CsrBanner bannerData={bannerData} />
            <CsrContent />
            <Footer />
        </>
    )
}

export default Csr