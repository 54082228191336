import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import TRCEfficientServicesBanner from './TRCEfficientServicesBanner/TRCEfficientServicesBanner'
import TRCEfficientServicesContent from './TRCEfficientServicesContent/TRCEfficientServicesContent'
import TRCEfficientServicesForms from './TRCEfficientServicesContent/TRCEfficientServicesForms'
import { getTrcEfficientData } from '../../Redux/Action/Action';
import { Helmet } from "react-helmet";
const TRCEfficientServices = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTrcEfficientData());

    }, []);

    const trcEfficientData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.trcEfficientDataList?.data) : []);

    return (
        <>
            <Helmet>
                {/* Page Title and Meta Tags */}
                <title>TRC Efficient & Services</title>
                <meta
                    name="description"
                    content="TRC Efficient & Services offers top-quality solutions in energy efficiency and management. Explore our services designed to optimize energy use and reduce costs for businesses and industries."
                />
                <meta name="keywords" content="TRC Efficient & Services" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/trc-efficient-services/" />

            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <TRCEfficientServicesBanner trcEfficientData={trcEfficientData} />
            <TRCEfficientServicesContent trcEfficientData={trcEfficientData} />
            <TRCEfficientServicesForms />
            <Footer />
        </>
    )
}

export default TRCEfficientServices