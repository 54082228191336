import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowMoreAboutPower.css";
import "./ResponsiveKnowMoreAboutPower.css";
import { getHomeEightSectionData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const JavascriptVoid = (event) => {
    event.preventDefault();
};

const Slider_group = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    centerMode: true,
    centerPadding: '10px',
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const KnowMoreAboutPower = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomeEightSectionData());

    }, []);

    const homeEightSectionData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.homeEightSectionDataList?.data) : []);

    return (
        <>
            {homeEightSectionData?.length != "0" ?
                <section className='know-more-about-power-section'>
                    <div className="container">
                        <div className="heading-dv">
                            <h3>Know More About Power and <br />Solar Power Systems</h3>
                        </div>
                        <Slider {...Slider_group} className='slider-parent-dv'>
                            {homeEightSectionData?.map((data) => (
                                <div className='slider-item-dv'>
                                    <div className="slider-item-img">
                                        <img src={data?.image ? `${host}/${data?.image}` : ""} alt={data?.title} className='img-fluid' />
                                    </div>
                                    <div className="slider-item-heading">
                                        <h4>{data?.title ? data?.title : "On Grid Inverter"}</h4>
                                    </div>
                                </div>
                            ))}

                        </Slider>
                    </div>
                </section>
                :
                <section className='know-more-about-power-section'>

                </section>}
        </>
    )
}

export default KnowMoreAboutPower