import React from 'react'
import "./KnowledgeCentrePageBanner.css"
import "./ResponsiveKnowledgeCenterPageBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const KnowledgeCentrePageBanner = (bannerData) => {

    return (
        <>
            {/* <section className='knowledge-center-page-banner-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="/images/help-and-support/heading-bg.png" alt="" />
                                <h1>Help & Support</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='knowledge-center-page-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src={bannerData?.bannerData?.knowledgeCenterBanner ? `${host}/${bannerData?.bannerData?.knowledgeCenterBanner}` : ""} alt="" className='img-fluid banner-img-tag' />
                            <div className="banner-top-content-absol business-heading text-start">
                                {/* <img src="/images/help-and-support/heading-bg.png" alt="" className='img-fluid' /> */}
                                {bannerData?.bannerData?.knowledgeCenterMainHeading1 && <div className='business-banner-heading'>
                                    <div className="container">
                                        <div className="text-center">
                                            <h1>{bannerData?.bannerData?.knowledgeCenterMainHeading1}
                                            </h1>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default KnowledgeCentrePageBanner