import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import "./CareerAtEaproOneContent.css"
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


import { getCityData, getCountryData, getJobListData, getReferenceData, getStateData } from '../../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const CareerAtEaproOneContent = () => {

  const dispatch = useDispatch();
  const { b64id } = useParams();
  const location = useLocation();
  const jobIdComing = atob(b64id)

  useEffect(() => {
    if (jobIdComing != 'all') {
      formik.setFieldValue('jobId', jobIdComing)
    }
  }, [jobIdComing])

  useEffect(() => {

    dispatch(getReferenceData());
    dispatch(getCountryData());
    dispatch(getJobListData({ type: "all", location: "", keyword: "" }));
  }, []);

  const blogCategoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.blogCategoryDataList?.data) : []);
  const referenceData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.referenceDataList?.data) : []);
  const countryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.countryDataList?.data) : []);
  const jobListData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.jobListDataList?.data) : []);



 

  const [phone, setPhone] = useState('');
  // Remove non-digit characters
  const cleanPhoneNumber = phone.replace(/\D/g, '');

  // Check if there are digits after the fourth digit
  const hasDigitsAfterFourth = cleanPhoneNumber.length > 8;

  const [altPhone, setaltPhone] = useState('');




  let [initialValues, setInitialValues] = useState({
    name: "",
    jobId: '',
    email: "",
    experience: "",
    mobile: "",
    altmobile: "",
    curSalary: "",
    fixed: "",
    variable: "",
    expSalary: "",
    noticePeriod: "",
    prefLocation: "",
    coreStrength: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    reference: "",
    image: "",
    resume: "",
    // countryCode: "",
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
      email: Yup.string().required("Email is required.").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid E-mail"),
      // mobile: Yup.string().required("Contact number. is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Contact number."),
      altmobile: Yup.string().matches(/^[0-9]{8,15}$/, "Please enter a valid Contact number."),
      jobId: Yup.string().required("Job is required"),
      curSalary: Yup.string().required("Current Salary is required"),
      experience: Yup.string().required("Total experience is required"),
      expSalary: Yup.string().required("Expected Salary is required"),
      noticePeriod: Yup.string().required("Notice Period is required"),
      coreStrength: Yup.string().required("Core Strength is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      postalCode: Yup.string().required("Postal Code is required").matches(/^[0-9]+$/, "Please enter a valid Postal Code."),
      reference: Yup.string().required("Reference is required"),
      country: Yup.string().required("Country is required"),
      // image: Yup.string().required("Profile Picture is required"),
      resume: Yup.string().required("Resume is required"),
      // countryCode: Yup.string().required("Country Code is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      try {


        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          }
        };

        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email)
        formData.append("experience", values.experience)
        formData.append("mobile", phone)
        formData.append("altmobile", values.altmobile)
        formData.append("curSalary", values.curSalary)
        formData.append("fixed", values.fixed)
        formData.append("variable", values.variable)
        formData.append("expSalary", values.expSalary)
        formData.append("noticePeriod", values.noticePeriod)
        formData.append("prefLocation", values.prefLocation)
        formData.append("coreStrength", values.coreStrength)
        formData.append("city", values.city)
        formData.append("state", values.state)
        formData.append("postalCode", values.postalCode)
        formData.append("country", values.country)

        formData.append("reference", values.reference)
        formData.append("image", values.image)
        formData.append("resume", values.resume)
        formData.append("jobId", values.jobId)
        // formData.append("countryCode", countryCode)

        setSubmitting(true);


        document.body.style.overflow = 'hidden';

        await axios.post(`${host}/web/career-cv`, formData, config)
          .then(function (response) {

            if (response.status == 200) {
              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.success(response.data.message);
              resetForm();
              setAvatar(null);
              setFileName('')
              setPhone('');
              setaltPhone('')
            } else {

              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.error(response.data.message);


            }
          })
          .catch(function (error) {

            document.body.style.overflow = 'auto';
            setSubmitting(false)
            toast.error('Error submitting form. Please try again.');


          })

      }
      catch (error) {

        document.body.style.overflow = 'auto';
        setSubmitting(false)
        toast.error('Error submitting form. Please try again.');

      }

    }
  })

  const [avatar, setAvatar] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
    } else {
      setFileName('');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAvatar(file);

  };
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click event on file input
    }
  };

  const handleCountryChange = (e) => {
    const id = e.target.value

    dispatch(getStateData(id))
  }

  const handleStateChange = (e) => {
    const id = e.target.value

    dispatch(getCityData(id))
  }
  const stateData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.stateDataList?.data) : []);
  const cityData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.cityDataList?.data) : []);





  return (
    <>
      {/* <section className="career-at-eapro-search-jobs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form action="">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label
                      htmlFor="search-by-keyword-id"
                      className="form-label"
                    >
                      Search by Keyword
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      id="search-by-keyword-id"
                    />
                  </div>
                  <div className="col-md-6 text-start">
                    <label
                      htmlFor="search-by-location-id"
                      className="form-label"
                    >
                      Search by Location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      id="search-by-location-id"
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="search-job-btn">
                      <a href="" className="">
                        Search Jobs
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      <section className="career-at-eapro-page-content-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="career-content-heading">
                <h5>Application Form</h5>
              </div>
            </div>
          </div>
          <div className="form-parent-custon-dv">
            <form
              className="row g-3 custom-row-form form-container"
              method="post"
              onSubmit={formik.handleSubmit}
            >
              <FocusError formik={formik} />

              <div className="col-md-6">
                <label htmlFor="validationServer01" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationServer01"
                  defaultValue=""
                  required=""
                  placeholder="Full Name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="validationServer01" className="form-label">
                  Job Title
                </label>
                <select
                  type="text"
                  className="form-select"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder="jobId"
                  name="jobId" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.jobId}
                >
                  <option selected="" disabled="" value="">
                    --Select Job--
                  </option>
                  {jobListData?.map((data) => (
                    <option value={data?.id}>{data?.title}</option>
                  ))}
                </select>
                <div className="ms-2 help-block with-errors">
                  {formik.touched.jobId && formik.errors.jobId ? (
                    <div className="text-danger">{formik.errors.jobId}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="email-id" className="form-label">
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email-id"
                  defaultValue=""
                  required=""
                  placeholder="Email ID"
                  name="email" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="total-experience" className="form-label">
                  Total Experience
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="total-experience"
                  defaultValue=""
                  required=""
                  placeholder="Total Experience"
                  name="experience" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.experience}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.experience && formik.errors.experience ? (
                    <div className="text-danger">
                      {formik.errors.experience}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">

                <label htmlFor="contact-no" className="form-label">
                  Contact No
                </label>
                <PhoneInput
                  placeholder='Phone no.'
                  required
                  defaultCountry="in"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
                <div className="ms-2 help-block with-errors">
                  {!hasDigitsAfterFourth && (
                    <div className="text-danger">Phone no. is required</div>
                  )}
                </div>
              </div>

              {/* <div className="col-md-5">

                <label htmlFor="contact-no" className="form-label">
                  Contact No
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact-no"
                  defaultValue=""
                  required=""
                  placeholder="Mobile Number"
                  name="mobile" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div className="text-danger">{formik.errors.mobile}</div>
                  ) : null}
                </div>
              </div> */}
              <div className="col-md-6 ">
                <label htmlFor="contact-no" className="form-label">
                  Alternate Contact No
                </label>
                <PhoneInput
                  // className=" border-0"
                  placeholder='Alternate Phone no.'
                  required
                  defaultCountry="in"
                  value={altPhone}
                  onChange={(altPhone) => setaltPhone(altPhone)}
                />

              </div>
              <div className="col-md-4">
                <label htmlFor="current-year-salary" className="form-label">
                  Current Yearly salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="current-year-salary"
                  defaultValue=""
                  required=""
                  placeholder="Current Yearly salary"
                  name="curSalary" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.curSalary}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.curSalary && formik.errors.curSalary ? (
                    <div className="text-danger">{formik.errors.curSalary}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="fixed" className="form-label">
                  Fixed
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fixed"
                  defaultValue=""
                  required=""
                  placeholder="Fixed"
                  name="fixed" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fixed}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.fixed && formik.errors.fixed ? (
                    <div className="text-danger">{formik.errors.fixed}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="variables" className="form-label">
                  Variables
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="variables"
                  defaultValue=""
                  required=""
                  placeholder="Variables"
                  name="variable" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.variable}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.variable && formik.errors.variable ? (
                    <div className="text-danger">{formik.errors.variable}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="expected-yearly-salary" className="form-label">
                  Expected Yearly Salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="expected-yearly-salary"
                  defaultValue=""
                  required=""
                  placeholder="Expected Yearly Salary"
                  name="expSalary" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.expSalary}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.expSalary && formik.errors.expSalary ? (
                    <div className="text-danger">{formik.errors.expSalary}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="notice-period" className="form-label">
                  Notice Period
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="notice-period"
                  defaultValue=""
                  required=""
                  placeholder="Notice Period"
                  name="noticePeriod" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.noticePeriod}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.noticePeriod && formik.errors.noticePeriod ? (
                    <div className="text-danger">
                      {formik.errors.noticePeriod}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="preferred-location" className="form-label">
                  Preferred Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="preferred-location"
                  defaultValue=""
                  required=""
                  placeholder="Preferred Location"
                  name="prefLocation" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.prefLocation}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.prefLocation && formik.errors.prefLocation ? (
                    <div className="text-danger">
                      {formik.errors.prefLocation}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="core-strength" className="form-label">
                  Core Strength
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="core-strength"
                  defaultValue=""
                  required=""
                  placeholder="Core Strength"
                  name="coreStrength" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.coreStrength}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.coreStrength && formik.errors.coreStrength ? (
                    <div className="text-danger">
                      {formik.errors.coreStrength}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="address-input" className="form-label">
                  Address
                </label>
                <select
                  className="form-select"
                  id="validationServerstate"
                  aria-describedby=""
                  required=""
                  placeholder="Country"
                  name="country"
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleCountryChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option selected="" disabled="" value="">
                    --Select Country--
                  </option>
                  {countryData?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
                <div className="ms-2 help-block with-errors">
                  {formik.touched.country && formik.errors.country ? (
                    <div className="text-danger">{formik.errors.country}</div>
                  ) : null}
                </div>

              </div>
              <div className="col-md-3 mt-5">
                <select
                  type="text"
                  className="form-select"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder="State"
                  name="state" // Add this line
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStateChange(e)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  <option selected="" disabled="" value="">
                    --Select State--
                  </option>
                  {stateData?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
                <div className="ms-2 help-block with-errors">
                  {formik.touched.state && formik.errors.state ? (
                    <div className="text-danger">{formik.errors.state}</div>
                  ) : null}
                </div>
              </div>


              <div className="col-md-3 mt-5">
                <select
                  type="text"
                  className="form-select"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder="City"
                  name="city" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                >
                  <option selected="" disabled="" value="">
                    --Select City--
                  </option>
                  {cityData?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
                <div className="ms-2 help-block with-errors">
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-danger">{formik.errors.city}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-3 mt-5">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder="Postal Code"
                  name="postalCode"
                  maxLength={6}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                />

                <div className="ms-2 help-block with-errors">
                  {formik.touched.postalCode && formik.errors.postalCode ? (
                    <div className="text-danger">
                      {formik.errors.postalCode}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor="referred-by" className="form-label">
                  Referred By/Source
                </label>
                <select
                  className="form-select"
                  id="referred-by"
                  aria-describedby=""
                  required=""
                  placeholder=""
                  name="reference"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reference}
                >
                  <option selected="" disabled="" value="">
                    --Select Reference--
                  </option>
                  {referenceData?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}

                </select>
                <div className="ms-2 help-block with-errors">
                  {formik.touched.reference && formik.errors.reference ? (
                    <div className="text-danger">{formik.errors.reference}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Upload Your Resume
                </label>
                <div className="upload-files-container">
                  <div className="drag-file-area">
                    <span className="material-icons-outlined upload-icon" onClick={handleUploadClick}>
                      {' '}
                      <img src="/images/career/clound.png" alt="" />
                    </span>
                    <h3 className="dynamic-message" onClick={handleUploadClick}>
                      {' '}
                      Drop any file here{' '}
                    </h3>
                    <label className="label">
                      <span className="browse-files">
                        <input
                          type="file"
                          className="default-file-input"
                          ref={fileInputRef}
                          name="resume"
                          accept=".pdf,.doc, .docx"
                          onChange={(e) => {
                            handleFileInputChange(e);
                            formik.setFieldValue('resume', e.target.files[0])
                          }
                          }
                          onBlur={formik.handleBlur}
                        />

                      </span>
                    </label>
                    <div className='mb-4 text-primary'>{fileName}</div>
                  </div>
                  <div className="ms-2 help-block with-errors">
                    {formik.touched.resume && formik.errors.resume ? (
                      <div className="text-danger">{formik.errors.resume}</div>
                    ) : null}
                  </div>
                  {/* <span className="cannot-upload-message">
                    <span className="material-icons-outlined">error</span>{' '}
                    Please select a file first
                    <span className="material-icons-outlined cancel-alert-button">
                      cancel
                    </span>
                  </span>
                  <div className="file-block">
                    <div className="file-info">
                      <span className="material-icons-outlined file-icon">
                        description
                      </span>
                      <span className="file-name"> </span> |{' '}
                      <span className="file-size"></span>
                    </div>
                    <span className="material-icons remove-file-icon">
                      delete
                    </span>
                    <div className="progress-bar"> </div>
                  </div>
                  <button type="button" className="upload-button">
                    Upload
                  </button> */}
                </div>
              </div>
              <div className="col-md-12 text-center ">
                <img
                  id="output-cus"
                  width={'100px'}
                  height={'100px'}
                  src={avatar ? URL.createObjectURL(avatar) : '/download.png'}
                />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3">
                <label
                  className="file-label-cus upload-photograph-parent"
                  htmlFor="file-id-cus"
                >
                  <span>Upload Your Photograph</span>
                  <input
                    type="file"
                    accept="image/*"
                    id="file-id-cus"
                    placeholder="Upload Your Photograph"
                    hidden
                    name="image"
                    onChange={(e) => {
                      handleFileChange(e);
                      formik.setFieldValue('image', e.target.files[0]);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <div className="icon-img">
                    <img src="/images/career/upload-icon.png" alt="" />
                  </div>
                </label>
                <div>{ }</div>
                <div className="ms-2 help-block with-errors">
                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-danger">{formik.errors.image}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn submit-btn-custom" type="submit" disabled={!hasDigitsAfterFourth}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div >
      </section >
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}

export default CareerAtEaproOneContent