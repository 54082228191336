import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import QualityAssuranceBanner from './QualityAssuranceBanner'
import QualityAssuranceContent from './QualityAssuranceContent'
import { getLearnMoreData } from "../../Redux/Action/Action";
import { Helmet } from "react-helmet";

const QualityAssurance = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getLearnMoreData());
    }, []);

    const LearnMoreData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.LearnMoreDataList?.data) : []);




    return (
        <>
            <Helmet>
                <title>Learn More | EAPRO</title>
                <meta name="description" content="Learn more about EAPRO cutting-edge solar energy solutions. Explore our products, services, and commitment to sustainability, helping you make informed decisions for a greener future." />
                <meta name="keywords" content="Learn More" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/learn-more/" />

            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <QualityAssuranceBanner LearnMoreData={LearnMoreData} />
            <QualityAssuranceContent LearnMoreData={LearnMoreData} />
            <Footer />
        </>
    )
}

export default QualityAssurance