import React, { useEffect } from 'react'
import "./CareerAtEaproTwoContent.css"
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
const CareerAtEaproTwoContent = (jobData) => {
  const data = jobData?.jobData

  return (
    <>
      {/* <section className='career-at-eapro-two-con-search-jobs'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form action="">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-keyword-id" className='form-label'>Search by Keyword</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-keyword-id' />
                  </div>
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-location-id" className='form-label'>Search by Location</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-location-id' />
                  </div>
                  <div className="col-md-12">
                    <div className="search-job-btn">
                      <a href="" className=''>Search Jobs</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      <section className='career-at-eapro-two-page-content-section'>
        <div className="container">
          {data ?
            <div className="form-parent-custon-dv text-start">

              <div className="card-parent-dv">
                {data?.title && <h4>{data?.title}</h4>}
                {data?.createdAt && <p><b>Date: {new Date(data?.createdAt).toDateString()}</b></p>}
                {data?.location && <p> <b>Location: {data?.location}</b></p>}
                {data?.company && <p><b>Company: {data?.company}</b></p>}
                {data?.jobObjective &&
                  <div className="job-objective-dv">
                    <p><b>Job Objective:</b></p>
                    <p>{data?.jobObjective}</p>
                  </div>
                }
                {data?.title &&
                  <div className="job-objective-dv">
                    <p><b>Designation/ Title</b></p>
                    <p>{data?.title}</p>
                  </div>
                }
                {data?.jobDescription &&
                  <div className="job-description-dv">
                    <p><b>Job Description:</b></p>
                    {parse(data?.jobDescription)}
                  </div>}
                {data?.requiredSkills &&
                  <div className="job-description-dv">
                    <p><b>Required Skills:</b></p>
                    {parse(data?.requiredSkills)}
                  </div>}
                {data?.whyJoinUs &&
                  <div className="job-description-dv">
                    <p><b>Why join us:</b></p>
                    {parse(data?.whyJoinUs)}
                  </div>}
                <div className="job-apply-btn">
                  <Link to={`/career-at-eapro-form/${btoa(data?.id)}`}>Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></Link>
                </div>
              </div>

            </div>
            :
            ""
          }
        </div>
      </section >
    </>
  )
}

export default CareerAtEaproTwoContent