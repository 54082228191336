import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import BussinessDivisionsBanner from './BussinessDivisionsBanner/BussinessDivisionsBanner'
import BussinessDivisionContent from './BussinessDivisionContent/BussinessDivisionContent'
import { getAllBannerData, getBusinessDivisionData } from '../../Redux/Action/Action';
import { Helmet } from "react-helmet";
const BussinessDivisionsPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBusinessDivisionData());
        dispatch(getAllBannerData());

    }, []);

    const businessDivisionData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.businessDivisionDataList?.data) : []);
    const businessDivisionBannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);

    return (
        <>
         <Helmet>
        {/* Page Title and Meta Tags */}
        <title>Business Division | EAPRO</title>
        <meta
          name="description"
          content="Business Division specializes in providing customized solar solutions for businesses and industries. We offer reliable solar inverters, batteries, and panels to help enterprises achieve energy efficiency, reduce costs, and promote sustainability."
        />
        <meta name="keywords" content="Business Division" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <link rel="canonical" href="https://www.eapro.in/business-division/" />

       
      </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <BussinessDivisionsBanner businessDivisionBannerData={businessDivisionBannerData} />
            <BussinessDivisionContent businessDivisionData={businessDivisionData} />
            <Footer />
        </>
    )
}

export default BussinessDivisionsPage