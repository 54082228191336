import React from 'react'
import "./HelpAndSupportBanner.css"
import "./ResponsiveHelpAndSupportBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const HelpAndSupportBanner = (bannerData) => {


    return (
        <>
            {/* <section className='help-and-support-banner-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="/images/help-and-support/heading-bg.png" alt="" />
                                <h1>Help & Support</h1>
                            </div> 
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='help-and-support-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src={bannerData?.bannerData?.supportBanner ? `${host}/${bannerData?.bannerData?.supportBanner}` : ""} alt="" className='img-fluid banner-img-tag' />
                            {bannerData?.bannerData?.supportHeading &&
                                <div className="banner-top-content-absol business-heading text-start">
                                    <img src="/images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                    <div className='business-banner-heading'>
                                        <div className="container">
                                            <h1>{bannerData?.bannerData?.supportHeading}</h1>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpAndSupportBanner