import React from 'react'
import "./BussinessDivisionsBanner.css"
import "./ResponsiveBussinessDivisionsBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const BussinessDivisionsBanner = (businessDivisionBannerData) => {

    return (
        <>
            {/* <section className='business-division-banner-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="business-heading-top">
                                <h1>Business</h1>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <div className="business-heading text-center">
                                <img src="/images/global-footprints/heading-bg.png" alt="" />
                                <h1>Divisions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='business-division-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src={businessDivisionBannerData?.businessDivisionBannerData?.businessDivisionBanner ? `${host}/${businessDivisionBannerData?.businessDivisionBannerData?.businessDivisionBanner}` : ""} alt="" className='img-fluid banner-img-tag' />
                            <div className="banner-top-content-absol text-start">
                                {/* <img src="/images/help-and-support/heading-bg.png" alt="" className='img-fluid' /> */}
                                <div className='business-banner-heading'>
                                    <div className="container">
                                        <div className="row">
                                            {/* <div className="col-md-12 text-center">
                                                <div className="business-heading-top">
                                                    <h1>{businessDivisionBannerData?.businessDivisionBannerData?.businessDivisionHeading1 ? businessDivisionBannerData?.businessDivisionBannerData?.businessDivisionHeading1 : "Business"}</h1>
                                                </div>
                                            </div> */}
                                            {businessDivisionBannerData?.businessDivisionBannerData?.businessDivisionHeading2 &&
                                                <div className="col-md-12 ">
                                                    <div className="business-heading text-center">
                                                        <img src="/images/global-footprints/heading-bg.png" alt="" />
                                                        <h1>{businessDivisionBannerData?.businessDivisionBannerData?.businessDivisionHeading2}</h1>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BussinessDivisionsBanner