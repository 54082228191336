import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../Header/Navbar'


import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import BlogDetailsBanner from './BlogDetailsBanner/BlogDetailsBanner'
import BlogDetailsContent from './BlogDetailsContent/BlogDetailsContent'
import { getBlogParamData } from '../../Redux/Action/Action';
import { Helmet } from 'react-helmet';

const BlogDetails = () => {

    const dispatch = useDispatch();




    const navigate = useNavigate();

    const { name } = useParams();
    useEffect(() => {
        if (!name) {
            toast.error("No Such Blog");


            navigate(-1)
        } else {

            dispatch(getBlogParamData(name));

        }
    }, [name])
    const blogData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.BlogParamDataList?.data) : []);




    return (
        <>
        
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <Helmet>
                {/* Page Title and Meta Tags */}
                <title>{blogData?.meta_title}</title>
                <meta
                    name="description"
                    content={blogData?.meta_description}
                />
                <meta name="keywords" content={blogData?.meta_keywords} />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href={`https://www.eapro.in/blog-details/${blogData?.slug}`} />

              
            </Helmet>
            <BlogDetailsBanner blogData={blogData} />
            <BlogDetailsContent blogData={blogData} />
            <Footer />
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}

export default BlogDetails