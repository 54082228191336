import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'

import { getAllBannerData } from '../../Redux/Action/Action';
import ECatalogueBannerNew from './ECataloguePageContent/ECatalogueBannerNew';
import ECataloguePageContent from './ECataloguePageContent/ECataloguePageContent';
import { Helmet } from "react-helmet";
const ECataloguePage = () => {



    return (
        <>
           <Helmet>
        {/* Page Title and Meta Tags */}
        <title>Download | Eapro</title>
        <meta
          name="description"
          content="Download essential resources, product brochures, and more from EAPRO. Access the latest information on our solar solutions and services with just a click."
        />
        <meta name="keywords" content="Download" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <link rel="canonical" href="https://www.eapro.in/download/" />

        {/* Organization Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "EAPRO Global Ltd.",
            "url": "https://www.eapro.in/",
            "logo": "https://www.eapro.in/images/logo.png",
            "sameAs": [
              "https://www.facebook.com/EaproGlobalLtd/",
              "https://www.instagram.com/eaprogloballtd/",
              "https://www.youtube.com/@eaprogloballimited",
            ],
          })}
        </script>

        {/* Website Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "EAPRO Global Ltd.",
            "url": "https://www.eapro.in/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.eapro.in/{search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            {/* <ECataloguePageBanner bannerData={bannerData} /> */}
            <ECatalogueBannerNew />
            <ECataloguePageContent />
            <Footer />
        </>
    )
}

export default ECataloguePage