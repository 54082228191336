import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import StoreLocatorBanner from './StoreLocatorBanner/StoreLocatorBanner'
import StoreLocatorContent from './StoreLocatorContent/StoreLocatorContent'
import { getAllBannerData } from '../../Redux/Action/Action';
import { Helmet } from "react-helmet";
const StoreLocatorPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);

    return (
        <>
         <Helmet>
        {/* Page Title and Meta Tags */}
        <title>Store Locator | Eapro</title>
        <meta
          name="description"
          content="Find your nearest Eapro store quickly and easily with our store locator. Explore our wide range of products and services at a location near you."
        />
        <meta name="keywords" content="Store Locator" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <link rel="canonical" href="https://www.eapro.in/store-locator/" />

       
      </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <StoreLocatorBanner bannerData={bannerData} />

            <Footer />
        </>
    )
}

export default StoreLocatorPage