import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import InstallationHelpPageBanner from './InstallationHelpPageBanner/InstallationHelpPageBanner'
import InstallationHelpPageContent from './InstallationHelpPageContent/InstallationHelpPageContent'
import { Helmet } from "react-helmet";
const InstallationHelpPage = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Design your perfect solar system with EAPRO. Our expert solutions help you create efficient, customized solar setups for homes and businesses, ensuring maximum energy savings and sustainability." />
                <meta name="keywords" content="Design Solar System" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/design-solar-system/" />
            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <InstallationHelpPageBanner />
            <InstallationHelpPageContent />
            <Footer />
        </>
    )
}

export default InstallationHelpPage