import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./StoreLocatorBanner.css";
import "./ResponsiveStoreLocatorBanner.css";
import { getStoreLocatorData } from '../../../Redux/Action/Action';

import StoreLocatorContent from '../StoreLocatorContent/StoreLocatorContent';
const host = process.env.REACT_APP_API_SERVER;

const StoreLocatorBanner = (bannerData) => {
    const dispatch = useDispatch();
    const [searchTerm, setsearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setsearchTerm(e.target.value);

    };

    const handleOnClick = () => {
        dispatch(getStoreLocatorData(searchTerm));
    }

    useEffect(() => {
        if (searchTerm == "") {
            dispatch(getStoreLocatorData(""));
        }
    }, [searchTerm])

    const storeLocatorData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.storeLocatorDataList?.data) : []);

    return (
        <>
            <section className='store-locator-banner-section' style={{ backgroundImage: `url(${host}${bannerData?.bannerData?.storeLocatorBanner ? bannerData?.bannerData?.storeLocatorBanner : "../../../../public/images/store-locator/banner-bg.png"})` }}>
                <div className="container">
                    <div className="row">
                        {bannerData?.bannerData?.storeLocatorHeading && <div className="col-md-12">
                            <div className="store-heading">
                                <img src="/images/store-locator/heading-bg.png" alt="" />
                                <h5>{bannerData?.bannerData?.storeLocatorHeading}</h5>
                            </div>
                        </div>}
                        <div className="col-md-12">
                            <div className="banner-img-dv">
                                <img src={bannerData?.bannerData?.storeLocatorImage ? `${host}/${bannerData?.bannerData?.storeLocatorImage}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="banner-dv-search">

                                <div className="banner-search-parent">
                                    <input type="text" placeholder='Enter State Or Pincode Or City' onChange={handleSearchChange} />
                                    <button className='banner-search-btn' onClick={handleOnClick}>Search</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <StoreLocatorContent storeLocatorData={storeLocatorData} />
        </>
    );
};

export default StoreLocatorBanner;
