import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./HelpAndSupport.css"
import { Link } from 'react-router-dom'
import { getContactDetailsData } from '../../../Redux/Action/Action';
const HelpAndSupport = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContactDetailsData());
  }, []);

  const contactDetailsData = useSelector((state) => state?.Reducer?.contactDetailsDataList?.data || []);

  return (
    <>
      <section className='help-and-support-section'>
        <div className="container">
          <div className="heading-dv">
            <h3>Help & Suport</h3>
          </div>
          <div className="help-support-inner-section">
            <div className="row align-items-center">
              <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1 ">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="first-card-parent">
                      <div className="mobile-tag">
                        <p><a href={`tel:${contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}`}><img src="../images/phone.png" alt={contactDetailsData?.mobile} />{contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}</a></p>
                        <p><img src="../images/alarm.png" alt={contactDetailsData?.whatsapp}  loading="lazy" />{contactDetailsData?.whatsapp ? contactDetailsData?.whatsapp : ""} </p>
                      </div>
                    </div>
                    {/* <div className="first-card-parent">
                      <div className="mobile-tag">
                        <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                      </div>
                    </div> */}
                    <div className="first-card-parent">
                      <div className="mobile-tag">
                        <p><a href={`mailto:${contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}`}><img src="../images/mail.png" alt={contactDetailsData?.email} loading="lazy" />{contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="second-side-card-parent">
                      <div className="mobile-tag">
                        <p>
                          <a href="http://eaproerp.site:3006" target='blank'><img src="../images/book-complaint.png" alt="Book a complaint" loading="lazy" />Book a complaint</a>
                          <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>

                        </p>
                      </div>
                    </div>
                    <div className="second-side-card-parent">
                      <div className="mobile-tag">
                        <p>
                          <a href="http://eaproerp.site:3006" target='blank'><img src="../images/register-product.png" alt="Register your product" loading="lazy" />Register your product</a>
                          <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                        </p>
                      </div>
                    </div>
                    <div className="second-side-card-parent">
                      <div className="mobile-tag">
                        <p>
                          <a href="http://eaproerp.site:3006" target='blank'><img src="../images/buy-amc.png" alt="Extended Warranty" loading="lazy" />Extended Warranty</a>
                          <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HelpAndSupport