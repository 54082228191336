import React from 'react'
import { Link } from 'react-router-dom'
import "./Error-new.css";
import Navbar from '../Header/Navbar';
import NavbarSecond from '../Header/NavbarSecond';
import MobileNavbar from '../Header/MobileNavbar';
import Footer from '../Footer/Footer';

const Error = () => {
    return (
        <>
        <Navbar />
        <NavbarSecond />
        <MobileNavbar />
        <div className='error-parent'>
            <Link to="#">
                <header className="top-header-errorpage">
                </header>

                <div>
                    <div className="starsec-errorpage"></div>
                    <div className="starthird-errorpage"></div>
                    <div className="starfourth-errorpage"></div>
                    <div className="starfifth-errorpage"></div>
                </div>


                {/* <div className="lamp__wrap-errorpage">
                    <div className="lamp-errorpage">
                        <div className="cable-errorpage"></div>
                        <div className="cover-errorpage"></div>
                        <div className="in-cover-errorpage">
                            <div className="bulb-errorpage"></div>
                        </div>
                        <div className="light-errorpage"></div>
                    </div>
                </div> */}
                <section className="error">
                    <div className="error__content">
                        <div className="error__message message">
                            <h1 className="message__title">Page Not Found</h1>
                            <p className="message__text">We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again, or take a look at our.</p>
                        </div>
                        <div className="error__nav e-nav">
                            <Link to="/" className="e-nav__link"></Link>
                        </div>
                    </div>

                </section>

            </Link>
        </div>
        <Footer /></>
    )
}

export default Error