import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ManufacturingPowerBanner from './ManufacturingPowerBanner'
import ManufacturingPowerContent from './ManufacturingPowerContent'
import { getWhyEaproData } from "../../Redux/Action/Action";
import { Helmet } from "react-helmet";

const ManufacturingPower = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWhyEaproData());
    }, []);

    const whyEaproData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.whyEaproDataList?.data) : []
    );


    return (
        <>
            <Helmet>
                <title>Why Eapro Detail</title>
                <meta name="description" content="Learn why EAPRO is the preferred choice for solar energy solutions. Discover our innovative products, commitment to quality, sustainability, and customer satisfaction that set us apart in the industry." />
                <meta name="keywords" content="Why Eapro Detail" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/why-eapro-detail/" />

            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ManufacturingPowerBanner whyEaproData={whyEaproData} />
            <ManufacturingPowerContent whyEaproData={whyEaproData} />
            <Footer />
        </>
    )
}

export default ManufacturingPower