import React from 'react'
import Navbar from '../Header/Navbar'
import Banner from '../Carousel/Banner'
import NavbarSecond from '../Header/NavbarSecond'
import DesignSolarPowerLoadCal from '../HomePageComponent/DesignSolarPowerLoadCal/DesignSolarPowerLoadCal'
import KnowMoreAboutPower from '../HomePageComponent/KnowMoreAboutPower/KnowMoreAboutPower'
import WhyEapro from '../HomePageComponent/WhyEapro/WhyEapro'
import OurSpecialization from '../HomePageComponent/OurSpecialization/OurSpecialization'
import PillarsOfEAPRO from '../HomePageComponent/PillarsOfEAPRO/PillarsOfEAPRO'
import KnowledgeCentreSlider from '../HomePageComponent/KnowledgeCentreSlider/KnowledgeCentreSlider'
import OurProductSection from '../HomePageComponent/OurProductSection/OurProductSection'
import HelpAndSupport from '../HomePageComponent/HelpAndSupport/HelpAndSupport'
import Footer from '../Footer/Footer'
import MobileNavbar from '../Header/MobileNavbar'
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <>
      <Helmet>
        {/* Page Title and Meta tags */}
        <title>EAPRO : Top Solar Inverter, Battery and Panel Company In India</title>
        <meta name="description" content="EAPRO, India's leading provider of high-quality solar inverters, batteries, and panels. Power your home or business with reliable, sustainable energy solutions from a trusted brand." />
        <meta name="keywords" content="Solar Inverter, Solar Battery, Solar Panel, Solar Panel Company India, EAPRO Solar Panel, EAPRO Solar Battery, EAPRO Solar Inverter, Eapro Global Ltd" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <link rel="canonical" href="https://www.eapro.in/" />



        <meta property="og:type" content="website" />
        <meta property="og:title" content=">EAPRO : Top Solar Inverter, Battery and Panel Company In India" />
        <meta property="og:description" content="EAPRO, India's leading provider of high-quality solar inverters, batteries, and panels. Power your home or business with reliable, sustainable energy solutions from a trusted brand." />
        <meta property="og:url" content="https://www.eapro.in/" />
        <meta property="og:image" content="https://www.eapro.in/images/specialization2.png" />

        
  <meta name="twitter:card" content="https://www.eapro.in/images/specialization2.png" />
  <meta name="twitter:title" content=">EAPRO : Top Solar Inverter, Battery and Panel Company In India" />
  <meta name="twitter:description" content="EAPRO, India's leading provider of high-quality solar inverters, batteries, and panels. Power your home or business with reliable, sustainable energy solutions from a trusted brand." />
  <meta name="twitter:image" content="https://www.eapro.in/images/specialization2.png" />


      </Helmet>
      <Navbar />
      <NavbarSecond />
      <MobileNavbar />
      <Banner />
      <WhyEapro />
      <OurSpecialization />
      <OurProductSection />
      <PillarsOfEAPRO />
      <KnowledgeCentreSlider />
      <DesignSolarPowerLoadCal />
      <KnowMoreAboutPower />
      <HelpAndSupport />
      <Footer />
    </>
  )
}

export default Home