import React from 'react'
import "./ViewProductSeriesListingContent.css"
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';

const host = process.env.REACT_APP_API_SERVER;
const ViewProductSeriesListingContent = (productListData) => {


  const navigate = useNavigate();
  const data = productListData?.productListData
  const handleClick = (name) => {

    if (name !== undefined) {
      navigate(`/product-details/${name}`)
    }
  }
  return (
    <>

      <section className="product-details-section">
        {/* graphic-1  */}
        {/* <div className="bg-graphic-right-top">
                    <img src="/images/power-solution/bg-graphic-1.png" className="img-fluid" />
                </div> */}
        <div className="container">
          {data?.length != 0 ?
            <div className="row justify-content-center">
              <h4 className="col-lg-12 text-center">{data?.[0]?.ChildCategoryyy?.name ? data?.[0]?.ChildCategoryyy?.name : ""}</h4>
              {/* ---- Product Item ----- */}
              <div className="col-lg-12 mb-5 text-start">
                {data?.map((product, index) => {
                  // Destructure capacity directly
                  const { specification } = product;

                  const specs = JSON.parse(specification);
                  let modelNumber = '';
                  let capacity = "";
                  try {
                    const modelTitles = ["Model No.", "Model number", "Model Number"];
                    const modelSpec = specs.find(spec => modelTitles.includes(spec.title));
                    modelNumber = modelSpec ? modelSpec.description : "";

                    // For Capacity
                    const capacityTitles = ["capacity", "Capacity"];
                    const capacitySpec = specs.find(spec => capacityTitles.includes(spec.title));
                    capacity = capacitySpec ? capacitySpec.description : "";
                  } catch (error) {
                    console.error('Error parsing specifications:', error);
                  }
                  return (
                    <div className="row mb-5" key={index}>
                      <div className="col-lg-4 mb-lg-0 mb-5">
                        <div className="product-item-img">
                          <img
                            src={product?.productImg?.[0]?.image ? `${host}/${product?.productImg?.[0]?.image}` : "./images/power-solution/product-one.png"}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 offset-lg-1">
                        <div className="product-item-info">
                          <h2>{product?.name ? product?.name : "TRANO-850S"}</h2>
                          <div className="key-features">
                            <h6>Key features</h6>
                            {product?.keyFeatures ? parse(product?.keyFeatures) : <ul>
                              <li>Nano technology for extra load handling</li>
                              <li>
                                Auto reset ac fuse technology during short circuit
                              </li>
                              <li>Active battery reverse protection</li>
                            </ul>}
                          </div>
                          {(modelNumber != "") &&
                            <div className="model">
                              <h6>Model No.</h6>
                              <h5>{modelNumber}</h5>
                            </div>}
                          {(capacity != "") &&
                            <div className="capacity">
                              <h6>Capacity</h6>
                              <h4>{capacity}</h4>
                            </div>}
                          <button onClick={() => { handleClick(product?.slug) }} className="view-product-btn">
                            View Product
                          </button>
                        </div>
                      </div>
                    </div>);
                })}
              </div>

            </div>
            :
            <h1>No Series Available</h1>
          }
        </div>
        {/* graphic-2  */}
        {/* <div className="bg-graphic-left-bottom">
                    <img src="/images/power-solution/bg-graphic-2.png" className="img-fluid" />
                </div> */}
      </section>
    </>
  );
}

export default ViewProductSeriesListingContent