import React, { useEffect, useState, } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./EaproGlobalEnergyForms.css"
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { PhoneInput } from 'react-international-phone';
import HourglassLoader from '../../HourglassLoader';
import { getCountryData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const EaproGlobalEnergyForms = () => {
    const [disableBtn, setDisableBtn] = useState(false);
    const [cphone, setPhone] = useState('');
    // Remove non-digit characters
    const cleanPhoneNumber = cphone.replace(/\D/g, '');

    // Check if there are digits after the fourth digit
    const hasDigitsAfterFourth = cleanPhoneNumber.length > 8;
    useEffect(() => {
        if (!hasDigitsAfterFourth) {
            setDisableBtn(true)
        }
        else {
            setDisableBtn(false)
        }
    }, [hasDigitsAfterFourth])

    const dispatch = useDispatch();


    useEffect(() => {


        dispatch(getCountryData());
    }, []);

    const countryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.countryDataList?.data) : []);

    let [initialValues, setInitialValues] = useState({
        name: "",
        // phone: "",
        email: "",
        address: "",
        type: "Distributorship & Dealership",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
            email: Yup.string().required("Email is required.").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid E-mail"),
            // phone: Yup.string().required("Phone number. is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Phone number."),
            address: Yup.string(),

        }),

        onSubmit: async (values, { resetForm, setSubmitting }) => {
            setDisableBtn(true);

            try {

                setSubmitting(true);


                document.body.style.overflow = 'hidden';
                const value = {
                    name: values.name,
                    email: values.email,
                    phone: cphone,
                    address: values.address,
                    type: "Distributorship & Dealership",

                }
                await axios.post(`${host}/web/contactUs`, value)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.success("Form Submitted Succesfully");
                            setDisableBtn(false)
                            resetForm();
                            setPhone('');
                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.error(response.data.msg);
                            setDisableBtn(false)

                        }
                    })
                    .catch(function (error) {

                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');
                        setDisableBtn(false)

                    })

            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
                setDisableBtn(false)
                resetForm()
            }

        }
    })


    return (
        <>
            <section className='global-energy-enquiry-section'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                            <div className="enquiry-heading">
                                <h5>Apply For Distributorship & Dealership</h5>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="enquiry-left-d">
                                <img src="/images/global-energy/apply-distri-dealer.png" alt="" className='img-fluid' />
                                <p className='mt-2'><b>Join us on this transformative journey towards a cleaner, more sustainable India. Together, let's power a brighter and greener future with Eapro Global Energy Pvt Ltd.</b></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="enquiry-form-right-dv">

                                <form method='post' onSubmit={formik.handleSubmit} >
                                    <FocusError formik={formik} />
                                    <div className="mb-4 pb-2">
                                        <input
                                            type="text"
                                            className="form-control rounded-0"
                                            placeholder='Name*'
                                            name="name" // Add this line
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                            maxLength={20}
                                        />

                                        <div className="d-flex ms-2 help-block with-errors">
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className='text-danger'>{formik.errors.name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <input
                                            type="email"
                                            className="form-control rounded-0"
                                            placeholder='Email*'
                                            name="email" // Make sure this matches your initialValues object key
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}

                                        />
                                        <div className="d-flex ms-2 help-block with-errors">
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className='text-danger'>{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* <div className="col-md-4 mb-4 pb-2">
                                            <select
                                                className="form-control rounded-0"
                                                id="referred-by"
                                                aria-describedby=""
                                                required=""
                                                placeholder=""
                                                name="countryCode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.countryCode}
                                            >
                                                <option selected="" disabled="" value="">
                                                    Country Code*
                                                </option>
                                                {countryData?.map((data) => (
                                                    <option value={data?.id}>{data?.name} ({data?.countryCode})</option>
                                                ))}

                                            </select>
                                            <div className="ms-2 help-block with-errors">
                                                {formik.touched.countryCode && formik.errors.countryCode ? (
                                                    <div className="text-danger">{formik.errors.countryCode}</div>
                                                ) : null}
                                            </div>
                                        </div> */}
                                    <div className=" mb-4 pb-2">
                                        <PhoneInput
                                            placeholder='Phone no.'
                                            required
                                            defaultCountry="in"
                                            value={cphone}
                                            onChange={(cphone) => setPhone(cphone)}
                                        />
                                        <div className="ms-2 help-block with-errors">
                                            {!hasDigitsAfterFourth && (
                                                <div className="text-danger text-start">Phone no. is required</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-4 pb-2">
                                        <textarea
                                            className="form-control rounded-0"
                                            rows="5"
                                            placeholder='Address'
                                            name="address" // Make sure this matches your initialValues object key
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address}
                                        >
                                        </textarea>
                                        <div className="d-flex ms-2 help-block with-errors">
                                            {formik.touched.address && formik.errors.address ? (
                                                <div className='text-danger'>{formik.errors.address}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-4 pb-2 text-start">
                                        <button type="submit" className="btn btn-primary enq-submit-btn" disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Submit"}</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />

        </>
    )
}

export default EaproGlobalEnergyForms