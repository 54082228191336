import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        if (pathname.includes("/career-at-eapro-details/")) {
            window.scrollTo({ top: 500, behavior: "smooth" });

        }
        else {
            window.scrollTo(0, 0);

        }

    }, [pathname]);
}

export default ScrollToTop;