import { React, useEffect } from 'react'
import EventPageBanner from './EventPageBanner/EventPageBanner'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import EventPageContent from './EventPageContent/EventPageContent'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllBannerData } from '../../Redux/Action/Action'
import { Helmet } from 'react-helmet'

const EventPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);



    return (
        <>
            <Helmet>
                <title>News Event | EAPRO</title>
                <meta name="description" content="Stay updated with the latest news and upcoming events from EAPRO. Discover our participation in industry events, product launches, and key developments in the solar energy sector." />
                <meta name="keywords" content="News Event" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/news-event/" />

            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EventPageBanner bannerData={bannerData} />
            <EventPageContent />
            <Footer />
        </>
    )
}

export default EventPage