import { React, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "./Footer.css";
import "./ResponsiveFooter.css";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { getContactDetailsData } from '../../Redux/Action/Action';
import TawkToWidget from '../ChatIntigration/TawkToWidget';
const host = process.env.REACT_APP_API_SERVER;
const Footer = () => {
    const [disableBtn, setDisableBtn] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactDetailsData());
    }, []);

    const contactDetailsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.contactDetailsDataList?.data) : []);



    let [initialValues, setInitialValues] = useState({

        emailid: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({

            emailid: Yup.string().required("Email is required.").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid E-mail"),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setDisableBtn(true)
            try {

                setSubmitting(true);




                await axios.post(`${host}/web/newsletter`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)

                            toast.success("Form Submitted Succesfully");
                            setDisableBtn(false)
                            resetForm()
                        } else {

                            setSubmitting(false)

                            toast.error(response.data.msg);
                            setDisableBtn(false)
                            resetForm()
                        }
                    })
                    .catch(function (error) {


                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');
                        setDisableBtn(false)
                        resetForm()
                    })

            }
            catch (error) {


                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
                setDisableBtn(false)
                resetForm()
            }

        }
    })


    const handlePolicyClick = (name) => {

        if (name !== undefined) {
            navigate(`/policies/${name}`)
        }
        else {
            toast.error("There is no such policy")
        }

    }

    return (
        <>
            <section className='footer-section'>
                <div className="footer-inner-dv">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Eapro Group</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                           
                                            <li><Link to="/blog">Blogs</Link></li>
                                            <li><Link to="/news-event">News & Event</Link></li>
                                            <li><Link to="/global-footprints">Global presence</Link></li>
                                            <li><Link to="/csr">CSR</Link></li>
                                            <li><a onClick={() => { handlePolicyClick("ethics_and_compliance") }} style={{ cursor: "pointer" }}>Ethics & Compliance</a></li>
                                            <li><Link to="/">5 Pillars of EAPRO</Link></li>
                                            <li><Link to="/business-division">Business divisions</Link></li>
                                            <li><Link to="/learn-more">Learn More</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Eapro care</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/store-locator">Store Locator</Link></li>
                                            <li><Link to="/service-center-locator">Service Center Locator </Link></li>
                                            <li><a href="http://eaproerp.site:3006" target='blank'>Product Registration </a></li>
                                            <li><a href="http://eaproerp.site:3006" target='blank'>Book Your Complaint </a></li>
                                            <li><a href="http://eaproerp.site:3006" target='blank'>Installation Help</a></li>
                                            <li><a href="http://eaproerp.site:3006" target='blank'>Extended Warranty</a></li>
                                        </ul>
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Our policies</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><a onClick={() => { handlePolicyClick("distributors_policy") }} style={{ cursor: "pointer" }}>Distributors Policy </a></li>
                                            <li><a onClick={() => { handlePolicyClick("dealers_policy") }} style={{ cursor: "pointer" }}>Dealers Policy </a></li>
                                            <li><a onClick={() => { handlePolicyClick("export_policy") }} style={{ cursor: "pointer" }}>Export Policy  </a></li>
                                            <li><a onClick={() => { handlePolicyClick("oem_odm_policy") }} style={{ cursor: "pointer" }}>OEM & ODM Policy  </a></li>
                                            <li><a onClick={() => { handlePolicyClick("privacy_policy") }} style={{ cursor: "pointer" }}>Privacy Policy</a></li>
                                            <li><a onClick={() => { handlePolicyClick("term_and_condition") }} style={{ cursor: "pointer" }}>Term & Condition</a></li>
                                        </ul>
                                    </div>

                                   

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Get in touch</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/contact-us">Contact US  </Link></li>
                                            <li><Link to="/eapro-overseas" >Export Queries </Link></li>
                                            <li><Link to="/global-energy-ltd">OEM / ODM Query </Link></li>
                                            <li><Link to="/contact-us"> Domestics Sales Query</Link></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Subscribe to our Newsletter</h3>
                                    </div>
                                    <div className="footer-content">
                                        <form method='post' onSubmit={formik.handleSubmit} >
                                            <FocusError formik={formik} />

                                            <div className="form-input-subscribe">
                                                <input type="text" placeholder='Enter Your Email' name="emailid" // Make sure this matches your initialValues object key
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.emailid}
                                                />
                                                <div className="at-rt-dv">
                                                    <img src="/images/email.png" alt="email" />
                                                </div>

                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.emailid && formik.errors.emailid ? (
                                                    <div className='text-danger'>{formik.errors.emailid}</div>
                                                ) : null}
                                            </div>
                                            <div className="subscribe-btn">
                                                <input type='submit' className='subs-btn-int' value="Subscribe" disabled={disableBtn} />
                                            </div>
                                        </form>

                                        <div className="mail-strip">
                                            <p><a href={`mailto:${contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}`}><img src="/images/mail.png" alt="mail" />{contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}</a></p>
                                        </div>
                                        <div className="whatsapp-strip">
                                            <p><a href={`tel:${contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}`}><img src="../images/phone.png" alt="phone" />{contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}</a></p>
                                        </div>
                                        <a href="https://wa.me/message/LXHBJ3N2B2YZN1" target='blank'> <div className="" style={{
                                            backgroundImage:
                                                'url("/whatsapp.png',
                                            height: "60px",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize:'contain',
                                            marginTop:'10px'
                                        }}>
                                                                                   </div></a>
                                    </div>
                                   
                                </div>
                            </div>
                           
                            <div className="col-lg-5 col-md-4">
                                <div className="footer-content-parent mt-2 mt-lg-5 mt-md-5">
                                    <div className="footer-heading">
                                        <h3>Corporate Tower</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link>{contactDetailsData?.address1 ? contactDetailsData?.address1 : "Upper Ganga canal Besides, Asaf Nagar, Delhi Rd, Roorkee, Uttarakhand 247666"} </Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="footer-content-parent mt-2 mt-lg-5 mt-md-5">

                                    <div className="footer-heading">
                                        <h3>Industrial Address</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link>{contactDetailsData?.address2 ? contactDetailsData?.address2 : "106-107,Salempur Rajputana Industrial Area, Roorkee, Uttarakhand 247666"}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="footer-content-parent mt-2 mt-lg-5 mt-md-5">
                                    <div className="footer-heading ">
                                        <h3>Follow Us</h3>
                                    </div>
                                    <div className="footer-content">
                                        <div className="follow-us-ul">
                                            <ul>
                                                <li>
                                                    <a href={contactDetailsData?.linkedIn ? `${contactDetailsData?.linkedIn}` : ""} target='_blank'>
                                                        <img src="/images/linkedin.png" alt="linkedin" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={contactDetailsData?.facebook ? `${contactDetailsData?.facebook}` : ""} target='_blank'>
                                                        <img src="/images/facebook.png" alt="facebook" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={contactDetailsData?.instagram ? `${contactDetailsData?.instagram}` : ""} target='_blank'>
                                                        <img src="/images/instagram.png" alt="instagram" className="img-fluid" />
                                                    </a>
                                                </li>
                                               
                                                <li>
                                                    <a href={contactDetailsData?.youtube ? `${contactDetailsData?.youtube}` : ""} target='_blank'>
                                                        <img src="/images/youtube.png" alt="youtube" className="img-fluid" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copy-right-div">
                                    <p>Copyright Eapro Global Limited 2024. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <TawkToWidget />
        </>
    )
}

export default Footer