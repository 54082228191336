import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import GlobalFootprintsBanner from './GlobalFootprintsBanner/GlobalFootprintsBanner'
import AboutExpert from './AboutExpert/AboutExpert'
import ExportEnquiryForm from './ExpertEnquiryForm/ExportEnquiryForm'
import EAPROOverseasForms from '../EAPROOverseas/EAPROOverseasContent/EAPROOverseasForms'
import { getAllBannerData } from '../../Redux/Action/Action';
import { Helmet } from "react-helmet";
const GlobalFootprintsPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);


    return (
        <>
         <Helmet>
        {/* Page Title and Meta Tags */}
        <title>Global Footprints | Eapro</title>
        <meta
          name="description"
          content="Explore EAPRO's global presence and commitment to providing top-tier solar energy solutions worldwide. Learn how we’re making a sustainable impact across different countries."
        />
        <meta name="keywords" content="Global Footprints" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <link rel="canonical" href="https://www.eapro.in/global-footprints/" />

       
      </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <GlobalFootprintsBanner bannerData={bannerData} />
            <AboutExpert />
            {/* <ExportEnquiryForm/> */}
            <EAPROOverseasForms />
            <Footer />
        </>
    )
}

export default GlobalFootprintsPage