import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getApplianceCategoryData, getApplianceData } from '../../../../Redux/Action/Action';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './LoadCalculator.css';

const LoadCalculator = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApplianceCategoryData());
    }, [dispatch]);

    const ApplianceData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.ApplianceDataList?.data) : []);
    const ApplianceCategoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.ApplianceCategoryDataList?.data) : []);

    const [totalWatt, setTotalWatt] = useState(0);
    const [counts, setCounts] = useState({});
    const [inverterCapacity, setinverterCapacity] = useState(0);
    const [numBatteries, setNumBatteries] = useState(0);
    const [batteryCapacity, setbatteryCapacity] = useState(0);

    // console.log('inverter:', inverterCapacity, 'battery:', batteryCapacity)

    const triggerModalOpen = () => {
        const openButton = document.getElementById('modal-open');
        if (openButton) {
            openButton.click();
        }

    };

    const formik = useFormik({
        initialValues: {
            percentage: '',
            time: '',
            totalWatt: 0,
            counts: {}
        },
        validationSchema: Yup.object().shape({
            percentage: Yup.string().required('Average Running Load is required'),
            time: Yup.number().required('Backup Requirement is required').integer('Backup Requirement must be an integer'),
            totalWatt: Yup.number()
                .required('Select appliance for calculating total watt')
                .moreThan(0, 'Total watt must be greater than zero'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const formData = {
                    percentage: values.percentage,
                    time: values.time,
                    totalWatt: values.totalWatt,
                    counts: values.counts
                };

                setSubmitting(true);
                // Perform calculations
                const backupTime = values.time; // hours
                const requiredBatteryStorage = values.totalWatt * backupTime; // in watt-hours (Wh)
                // console.log('requiredBatteryStorage', requiredBatteryStorage)
                // Calculate the number of batteries needed
                const leadAcidBatteryCapacity = 1300; // in Wh (for 150Ah battery)
                const numBatteries = Math.ceil(requiredBatteryStorage / leadAcidBatteryCapacity);
                // console.log('numBatteries', numBatteries)
                // Step 3: Calculate inverter capacity
                const inverterCapacity = values.totalWatt + (values.totalWatt * 0.20); // Add 20% margin


                setinverterCapacity(inverterCapacity.toFixed(2));
                setbatteryCapacity(requiredBatteryStorage.toFixed(2));
                setNumBatteries(numBatteries);

                triggerModalOpen()
               
            } catch (error) {
                setSubmitting(false);
                alert('Error submitting form');
            }
        },
    });

    const handleAdd = (id, watt) => {
        const wattValue = parseFloat(watt);
        const newTotalWatt = totalWatt + wattValue;
        const newCounts = { ...counts, [id]: (counts[id] || 0) + 1 };

        setTotalWatt(newTotalWatt);
        setCounts(newCounts);
        formik.setFieldValue('totalWatt', newTotalWatt);
        formik.setFieldValue('counts', newCounts);
    };

    const handleSubtract = (id, watt) => {
        const wattValue = parseFloat(watt);
        if (counts[id] > 0) {
            const newTotalWatt = totalWatt - wattValue;
            const newCounts = { ...counts, [id]: counts[id] - 1 };

            setTotalWatt(newTotalWatt);
            setCounts(newCounts);
            formik.setFieldValue('totalWatt', newTotalWatt);
            formik.setFieldValue('counts', newCounts);
        }
    };

    // const handleCategoryClick = (id) => {
    //     setActiveCategory(id);
    //     dispatch(getApplianceData(id));
    // };

    // const handleReset = () => {
    //     resetForm();
    //     setCounts({});
    //     setTotalWatt(0);
    // }

    return (
        <section className='load-calculator'>
            <div className="container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row form-ui">
                        <div className="col-md-12 text-start heading">
                            <img src="/images/one-image.png" className='count-img' alt="" />
                            <span><strong>DEFINE YOUR LOAD REQUIREMENT</strong></span>
                            <hr />
                        </div>
                        {ApplianceCategoryData?.map((data, index) => {
                            const categoryId = `category-${index}`;
                            const collapseId = `collapse-${index}`;
                            return (
                                <div className="col-md-6 accordion-outer-box" key={categoryId}>
                                    <div className="accordion" id={categoryId}>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={`${categoryId}-heading`}>
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#${collapseId}`}
                                                    aria-expanded="true"
                                                    aria-controls={collapseId}
                                                // onClick={() => handleCategoryClick(data.id)}
                                                >
                                                    {data?.name}
                                                </button>
                                            </h2>
                                            <div
                                                id={collapseId}
                                                className="accordion-collapse collapse show accordion-body-box"
                                                aria-labelledby={`${categoryId}-heading`}
                                                data-bs-parent={`#${categoryId}`}
                                            >
                                                <div className="accordion-body">
                                                    <table className="table mb-0 news-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Appliances</th>
                                                                <th>Wattage</th>
                                                                <th>Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.Appliance?.map((value, idx) => (
                                                                <tr key={idx}>
                                                                    <td className='col-md-4'>{value?.name}</td>
                                                                    <td className='col-md-4'>{value?.watt}</td>
                                                                    <td className='col-md-4'>
                                                                        <i className="fa fa-plus mx-2" onClick={() => handleAdd(value?.id, value?.watt)}></i>
                                                                        <span>{counts[value?.id] || 0}</span>
                                                                        <i className="fa fa-minus mx-2" onClick={() => handleSubtract(value?.id, value?.watt)}></i>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row form-ui">
                        <div className="col-md-12 text-start heading">
                            <img src="/images/two-image.png" className='count-img' alt="" />
                            <span><strong>DEFINE YOUR BACKUP REQUIREMENT</strong></span>
                            <hr />
                        </div>
                        <div className="row my-5">
                            <div className="col-md-4 text-start">
                                <label htmlFor="percentage">Average Running Load:</label>
                                <select
                                    id="percentage"
                                    name="percentage"
                                    className='form-control mt-2'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.percentage}
                                >
                                    <option value="" label="Select percentage" />
                                    <option value="25" label="25%" />
                                    <option value="50" label="50%" />
                                    <option value="75" label="75%" />
                                    <option value="100" label="100%" />
                                </select>
                                <div className="ms-2 help-block with-errors">
                                    {formik.touched.percentage && formik.errors.percentage ? (
                                        <div className="text-danger">{formik.errors.percentage}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4  text-start">
                                <label htmlFor="time">Backup Requirement (in hours):</label>
                                <input
                                    min={0}
                                    type="number"
                                    className='form-control mt-2'
                                    id="time"
                                    name="time"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.time}
                                />
                                <div className="ms-2 help-block with-errors">
                                    {formik.touched.time && formik.errors.time ? (
                                        <div className="text-danger">{formik.errors.time}</div>
                                    ) : null}
                                </div>

                            </div>
                            <div className='col-md-4  text-start'>
                                <label htmlFor="totalWatt">Total Watt: </label>
                                <input
                                    className='form-control mt-2'
                                    id="totalWatt"
                                    name="totalWatt"
                                    value={formik.values.totalWatt}
                                    readOnly
                                />
                                <div className="ms-2 help-block with-errors">
                                    {formik.touched.totalWatt && formik.errors.totalWatt ? (
                                        <div className="text-danger">{formik.errors.totalWatt}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12">
                                {/* <button type="button" className='btn btn-dark' onClick={handleReset}>Reset Form</button> */}
                                <button type="submit" className='btn btn-dark' disabled={formik.isSubmitting}>Calculate</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <>

                {/* Modal */}
                <button
                    type="button"
                    className="btn btn-primary d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    id='modal-open'
                ></button>
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content  ">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Inverter Recommendations for you
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p className='text-start'>Inverter Capacity: <span className='ms-3'>{inverterCapacity} VA</span></p>
                                <p className='text-start'>Battery Capacity: <span className='ms-3'>150 Ah</span></p>
                                <p className='text-start'>No. of battery: <span className='ms-3'>{numBatteries}</span></p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Recalculate
                                </button>
                                <button type="button" className="btn btn-primary">
                                    Buy Inverter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        </section>
    );
};

export default LoadCalculator;
